// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import split from 'lodash/split'
import replace from 'lodash/replace'
import trim from 'lodash/trim'

import classNames from 'classnames'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

import StandardPageWrapper from '../standard-page-wrapper'
import '../standard-page-wrapper/style.less'

import './style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query AuraTalkPageWrapperQuery {
    allAuraTalksJson {
      edges {
        node {
          title
          routeSlug
          publishedTimestamp
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page Wrapper */
const PageWrapper = ({ children, className = null, ...rest }) => (
  <StaticQuery
    query={query}
    render={(data) => {
      const {
        allAuraTalksJson: { edges },
      } = data

      const nodes = map(edges, 'node')
      const ordered = orderBy(nodes, ['publishedTimestamp'], ['desc'])

      const {
        pageContext: {
          intl: { originalPath },
        },
      } = rest

      return (
        <StandardPageWrapper
          className={classNames(className, 'aura-talk-page')}
          {...rest}
        >
          <Row
            gutter={[
              { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
              { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
            ]}
          >
            <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
              {children}
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <aside style={{ marginLeft: '1.1rem' }}>
                <h2>Season 2</h2>
                <ul>
                  {map(ordered, ({ title, routeSlug, publishedTimestamp }) => {
                    let [seasonEpisode, subTitle] = split(title, ':')
                    seasonEpisode = trim(
                      replace(seasonEpisode, 'Aura Talks', '')
                    )
                    subTitle = trim(subTitle)
                    const dateTime = new Date(
                      publishedTimestamp * 1000
                    ).toLocaleDateString('en-US')

                    return (
                      <li>
                        <Link
                          to={routeSlug}
                          style={{
                            maxWidth: 'unset',
                            marginBottom: 0,
                            textAlign: 'left',
                            whiteSpace: 'pre-line',
                          }}
                          className={classNames({
                            active: originalPath === routeSlug,
                          })}
                        >
                          {subTitle}
                          <small
                            style={{
                              fontSize: '80%',
                              display: 'block',
                              marginTop: '0.15rem',
                            }}
                          >
                            {seasonEpisode}, {dateTime}
                          </small>
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </aside>
            </Col>
          </Row>
        </StandardPageWrapper>
      )
    }}
  />
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default PageWrapper
