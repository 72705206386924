// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

import map from 'lodash/map'
import filter from 'lodash/filter'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Breadcrumbs from '../breadcrumbs'
import '../breadcrumbs/style.less'

import Video from '../video'
import '../video/style.less'

import AuraTalkPageWrapper from '../aura-talk-page-wrapper'
import '../aura-talk-page-wrapper/style.less'

import './style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const pageQuery = graphql`
  query AuraTalkPageTemplateQuery($routeSlug: String) {
    allAuraTalksJson(filter: { routeSlug: { eq: $routeSlug } }) {
      nodes {
        title
        routeSlug
        publishedTimestamp
        contentBlocks {
          type
          content
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
class Page extends React.PureComponent {
  /** [constructor description] */
  constructor() {
    super()
  }

  /** [render description] */
  render() {
    const {
      data: {
        allAuraTalksJson: {
          nodes: [{ title, routeSlug, publishedTimestamp, contentBlocks }],
        },
      },
      pageContext: { next, prev, humanDate },
    } = this.props

    const pageSchema = {
      title,
      slug: routeSlug.substring(1),
      abstract:
        'Our podcast invites a diverse range of community members and explores with them the potentialities of Circular Basic Income in Auroville.',
      breadcrumbs: [
        { title: 'Homepage', slug: '' },
        { title: 'Aura Talks', slug: '/aura-talks' },
        { title, slug: routeSlug.substring(1) },
      ],
    }

    const [videoBlock] = filter(contentBlocks, { type: 'video' })

    return (
      <AuraTalkPageWrapper pageSchema={pageSchema} {...this.props}>
        <Breadcrumbs
          breadcrumbs={pageSchema.breadcrumbs}
          prev={prev}
          next={next}
        />
        <h1>{title}</h1>
        <Video url={videoBlock.content} />
        <p>
          Our podcast invites a diverse range of community members and explores
          with them the potentialities of Circular Basic Income in Auroville.
        </p>
        {map(contentBlocks, (block) => {
          const { type, content, resource } = block
          let returnThis = <Fragment />

          if (type === 'text') {
            returnThis = <p>{content}</p>
          }

          if (type === 'strong') {
            returnThis = <h2>{content}</h2>
          }

          return returnThis
        })}
      </AuraTalkPageWrapper>
    )
  }
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    headerState: state.headerState,
  })
  // dispatch => ({
  //   updateHeaderState(payload) {
  //     dispatch(updateHeaderState(payload))
  //   },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
